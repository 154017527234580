<template>
  <div class="body">
    <div class="container">
      <nav class="topbar">
        <div class="brand">
          <router-link to="/" class="brand-icon">
            <img src="@/assets/Logo@2x.png" class="brand-logo" />
          </router-link>
        </div>
      </nav>
      <div class="page">
        <div class="alert" v-if="quit">
          <h2>Compte bloqué ! veuillez contacter un administrateur !</h2>
          <button @click="logout">{{ $t("LOGOUT") }}</button>
        </div>
        <h1>Votre Compte est suspendu,</h1>
        <p>Veuillez contacter un administrateur pour le debloquer.</p>
        <b-button variant="dark" @click="logout">{{ $t("LOGOUT") }}</b-button>
      </div>
    </div>

    <!-- <footer>
      <div class="link">
        <router-link to="/about_us">{{ $t("ABOUT_US") }}</router-link>
        <router-link to="/privacy">{{ $t("LEGAL_MENTION") }}</router-link>
      </div>
      <p>{{ $t("COPYRIGHT") }}</p>
    </footer> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      quit: 0,
    };
  },

  methods: {
    ...mapActions(["logout", "onlineUser"]),
  },

  computed: {
    ...mapGetters(["getToken", "getOnlineUser"]),
  },

  mounted() {
    this.onlineUser().then(() => {
      if (this.getOnlineUser.active) this.$router.push("/");
    });
  },

  beforeRouteLeave(to, from, next) {
    if (this.getOnlineUser)
      if (!this.getOnlineUser.active) {
        this.quit = 1;
        return;
      }
    next();
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  margin-top: 55px;
  min-height: 80vh;
  @media only screen and (max-width: 900px) {
    padding: 20px;
  }
}

.body {
  display: block;
  width: 100%;
}

footer {
  @media only screen and (max-width: 900px) {
    flex-direction: column;
  }
  border-top: 13px solid #e4261b;
  display: flex !important;
  justify-content: space-around;
  align-items: center;
  background-color: #28367a;
  height: 92px;
  color: #fff;
  .link {
    @media only screen and (max-width: 900px) {
      width: 100%;
      justify-content: space-around;
    }
    width: 35%;
    display: flex;
    justify-content: space-between;

    & a {
      text-decoration: underline;
      color: #fff;

      &:hover {
        color: #ffffff80;
      }
    }
  }

  & p {
    margin: 0;
    font-size: 12px;
    font-weight: 500;
  }
}

.page {
  text-align: center;

  .alert {
    position: fixed;
    top: 56px;
    z-index: 0;
    left: 0;
    right: 0;
    min-width: 100px;
    padding: 12px;
    background-color: #1e1e1e;
    color: #fff;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      height: 36px;
      min-width: 64px;
      padding: 0 16px;
      background-color: #272727;
      color: #fff;
      font-size: 0.875rem;

      &:hover {
        background-color: #1e1e1e;
      }
    }

    h2 {
      font-size: 16px;
      margin: 0;
    }
  }
}
</style>
